import vClickOutside from 'v-click-outside';

export default {
  name: 'MegaMenu',
  props: {},
  data() {
    return {
      visibleMenuId: -1, // Search overlay = 0
      menuMainOverlay: null
    };
  },
  mounted() {
    // Remove bottom border if hero element exists
    const pageHeros = 'div.hierarchical-page-hero, div.landing-page-hero__top';
    const mainLinkSelected = document.querySelector('.menu-main__link--selected');
    if (document.querySelectorAll(pageHeros).length > 0) {
      if (mainLinkSelected) {
        mainLinkSelected.classList.add('menu-main__link--no-bottom-border');
      }
    }

    this.menuMainOverlay = document.getElementById('menu_main_overlay');
  },
  methods: {
    isVisibleMenu(menuId) {
      return menuId === this.visibleMenuId;
    },
    setVisibleMenu(menuId) {
      // If menu is already visible, hide it, otherwise show it
      this.visibleMenuId = this.getVisibleMenuId(menuId);
      if (this.menuMainOverlay) {
        if (this.getVisibleMenuId(menuId) !== -1) {
          this.menuMainOverlay.style.display = 'none';
        } else {
          this.menuMainOverlay.style.display = 'block';
        }
      }
    },
    getVisibleMenuId(menuId) {
      return this.visibleMenuId === menuId ? -1 : menuId;
    },
    hideMenu() {
      if (this.visibleMenuId === -1) return;

      this.visibleMenuId = -1;
      if (this.menuMainOverlay) {
        this.menuMainOverlay.style.display = 'none';
      }
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  }
};
